import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import appstore from "../../assets/icon/appstore.png";
import ggplay from "../../assets/icon/ggplay.png";
import iconH1 from "../../assets/icon/iconH1.png";
import iconH2 from "../../assets/icon/iconH2.png";
import iconH3 from "../../assets/icon/iconH3.png";
import iconH4 from "../../assets/icon/iconH4.png";
import iconH5 from "../../assets/icon/iconH5.png";
import iconH6 from "../../assets/icon/iconH6.png";
import tick from "../../assets/icon/tick-02.svg";
import bnImgHome from "../../assets/images/bnImgHome.png";
import imgH1 from "../../assets/images/imgH1.png";
import imgH2 from "../../assets/images/imgH2.png";
import imgH3 from "../../assets/images/imgH3.png";
import Banner from "../../components/Banner/Banner";
import { partners } from "../../data/partner";
import "./Home.scss";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        Aos.init({ duration: 1300 });
    }, []);

    return (
        <>
            <div className="section-top">
                <div className="container">
                    <Banner
                        img={bnImgHome}
                        title={"Chào mừng bạn đến với HPay"}
                        desc={["HPay cung cấp các giải pháp trung gian thanh toán toàn diện với nhiều dịch vụ và tiện ích cho tất cả khách hàng."]}
                        bgr={"to right, #c4d0e4, #e1eaef"}
                        apps={[ggplay, appstore]}
                    />
                   
                    <div className="section-top-big">
                        <div className="row">
                            <div className="col-lg-6 col-12 pt-md-5 ps-md-5">
                                <div data-aos="fade-right">
                                    <h2 className="fs-1 fw-bold">HPay đáp ứng mọi nhu cầu thanh toán</h2>
                                    <p className="desc-2 my-4 text-success">Dễ dàng, tiện lợi, an toàn mọi lúc mọi nơi</p>
                                    <ul className="list-unstyled">
                                        <li className="mb-2">
                                            <img src={tick} className="me-2" alt="tick" />
                                            <span className="desc-2">Thanh toán hóa đơn mua hàng</span>
                                        </li>
                                        <li className="mb-2">
                                            <img src={tick} className="me-2" alt="tick" />
                                            <span className="desc-2">Thanh toán học phí</span>
                                        </li>
                                        <li className="mb-2">
                                            <img src={tick} className="me-2" alt="tick" />
                                            <span className="desc-2">Thanh toán điện, nước, internet...</span>
                                        </li>
                                        <li className="mb-2">
                                            <img src={tick} className="me-2" alt="tick" />
                                            <span className="desc-2">Thanh toán viện phí</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div data-aos="fade-left">
                                    <img src={imgH1} className="img-fluid" alt={imgH1} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-distance">
                        <div className="row mt-5">
                            <div className="col-12">
                                <div className="home-box">
                                    <div className="wrapper-box">
                                        <h2 className="text-center fs-1 fw-bold ">Giải pháp tối ưu, Tối đa lợi nhuận an toàn tiện lợi</h2>
                                        <div className="d-flex justify-content-center flex-wrap">
                                            <div className="box-items-left me-sm-3">
                                                <div data-aos="fade-right">
                                                    <div className="box-item">
                                                        <img src={iconH2} alt="" />
                                                        <span className="desc-2">Tiết kiệm thời gian</span>
                                                    </div>
                                                </div>
                                                <div data-aos="fade-right">
                                                    <div className="box-item">
                                                        <img src={iconH6} alt="" />
                                                        <span className="desc-2">Tối ưu vận hành</span>
                                                    </div>
                                                </div>
                                                <div data-aos="fade-right">
                                                    <div className="box-item">
                                                        <img src={iconH3} alt="" />
                                                        <span className="desc-2">Giảm thiểu gian lận</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-items-right">
                                                <div data-aos="fade-left">
                                                    <div className="box-item">
                                                        <img src={iconH1} alt="" />
                                                        <span className="desc-2">Bảo mật tuyệt đối</span>
                                                    </div>
                                                </div>
                                                <div data-aos="fade-left">
                                                    <div className="box-item">
                                                        <img src={iconH5} alt="" />
                                                        <span className="desc-2">Tùy biến giao diện</span>
                                                    </div>
                                                </div>
                                                <div data-aos="fade-left">
                                                    <div className="box-item">
                                                        <img src={iconH4} alt="" />
                                                        <span className="desc-2">Dễ dàng triển khai</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home2 py-5">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-6 col-12">
                            <h2 className="text-light fw-bold fs-1 mb-4">Cổng thanh toán</h2>
                            <span className="desc-3">
                                Cổng thanh toán HPay cung cấp giải pháp thanh toán Online nhanh chóng hiện đại và an toàn cho khách hàng, giúp thay
                                đổi thói quen mua sắm, tiêu dùng bằng tiền mặt truyền thống
                            </span>
                            <div className=" my-4 d-flex gap-3">
                                <Link to={"/san-pham"} className="button-link-large">
                                    Tìm hiểu ngay
                                </Link>
                                <a href="https://api-ref.hpay.com.vn/" className="button-link-large-replace" target="_blank" rel="noreferrer">
                                    Tích hợp tại đây
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div data-aos="fade-left">
                                <img src={imgH2} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center mt-5">
                        <div className="col-lg-6 col-md-6 col-12 order-2 order-md-1">
                            <div data-aos="fade-right">
                                <div className="w-100 h-100">
                                    <img src={imgH3} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 order-1 order-md-2">
                            <h2 className="text-light fw-bold fs-1 mb-4">Dịch vụ hỗ trợ thu hộ, chi hộ</h2>
                            <span className="desc-3">
                                HPay cung cấp dịch vụ cho các khách hàng có nhu cầu thu hộ - chi hộ thông qua mạng lưới giao dịch rộng khắp
                            </span>
                            <div className="mt-4">
                                <Link to={"/san-pham/thu-ho-chi-ho"} className="button-link-large">
                                    Tìm hiểu ngay
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-12">
                            <h2 className="text-center text-light fs-1 fw-bold">Đối tác của HPay</h2>
                            <div className="d-flex flex-wrap gap-3 justify-content-center mt-5">
                                {partners?.map((partner, index) => {
                                    return <img key={index} className="partner-item-img" src={partner} alt="" />;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
