import { useEffect, useRef, useState } from "react";
import "./Header.scss";
import { Link, useLocation } from "react-router-dom";
import email from "../../assets/icon/email-menu2.png";
import phone from "../../assets/icon/phone-menu2.png";
import instagram from "../../assets/icon/in-menu2.png";
import facebook from "../../assets/icon/fb-menu2.png";
import youtube from "../../assets/icon/yt-menu2.png";
import logo from "../../assets/icon/logo1.png";
import menu from "../../assets/icon/menu.png";
import d from "../../assets/images/d.png";
import { Dropdown } from "antd";
import { FaChevronDown } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";

const Header = () => {
    const location = useLocation();
    const navbarItem = location.pathname.split("/")[1];
    const [activeItem, setActiveItem] = useState(navbarItem);
    const headerRef = useRef();
    const headerTopRef = useRef();
    useEffect(() => {
        setActiveItem(navbarItem);
    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current) {
                headerRef.current.classList.toggle("sticky", window.scrollY > 10);
                headerTopRef.current.classList.toggle("d-none", window.scrollY > 200);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const items = [
        {
            key: "1",
            label: (
                <Link to={"/san-pham"} className="fs-6">
                    Cổng thanh toán
                </Link>
            ),
        },
        {
            key: "2",
            label: (
                <Link to={"/san-pham/vi-dien-tu"} className="fs-6">
                    Ví điện tử
                </Link>
            ),
        },
        {
            key: "3",
            label: (
                <Link to={"/san-pham/thu-ho-chi-ho"} className="fs-6">
                    Dịch vụ Thu hộ/Chi hộ
                </Link>
            ),
        },
    ];

    return (
        <header ref={headerRef} className="position-fixed top-0 w-100" style={{ zIndex: 9999 }}>
            <div ref={headerTopRef} className="header2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-none d-md-block">
                            <div className="d-flex justify-content-end align-items-center">
                                <div className="d-flex border-end">
                                    <div className="d-flex align-items-center me-3">
                                        <img src={email} className="me-2" style={{ width: "12px", height: "auto" }} alt="" />
                                        <span className="contact-content">
                                            Email:
                                            <a href="mailto:support@htpgroup.com.vn">support@hpay.com.vn</a>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center me-3">
                                        <img src={phone} className="me-2" style={{ width: "12px", height: "auto" }} alt="" />
                                        <span className="contact-content">
                                            Hotline:
                                            <a href="tel:1900 0128">1900 0128</a>
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center gap-3 ms-3">
                                    <a href="#">
                                        <img src={instagram} style={{ width: "12px", height: "auto" }} alt="instagram" />
                                    </a>
                                    <a href="#">
                                        <img src={facebook} style={{ width: "12px", height: "auto" }} alt="facebook" />
                                    </a>
                                    <a href="#">
                                        <img src={youtube} style={{ width: "12px", height: "auto" }} alt="youtube" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-3 col-md-3">
                            <Link to={"/"}>
                                <img src={logo} style={{ width: "128px", height: "auto" }} alt="" />
                            </Link>
                        </div>
                        <div className="col-lg-9 d-none d-lg-flex justify-content-end">
                            <ul className="d-flex align-items-center gap-2 list-unstyled">
                                <li className={activeItem === "" ? "item-navbar active" : "item-navbar"}>
                                    <div>
                                        <Link to={"/"}>TRANG CHỦ</Link>
                                    </div>
                                </li>
                                <li className={activeItem === "ve-chung-toi" ? "item-navbar active" : "item-navbar"}>
                                    <div>
                                        <Link to={"/ve-chung-toi"}>VỀ HPAY</Link>
                                    </div>
                                </li>
                                <li style={{ height: "30px", width: "120px", marginBottom: "5px" }} className="d-flex align-items-center">
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                        placement="top"
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        arrow
                                    >
                                        <div
                                            style={{
                                                background: "transparent",
                                                border: 0,
                                                color: "#323337",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                zIndex: 9999,
                                            }}
                                            type="button"
                                            className="d-flex align-items-center"
                                        >
                                            SẢN PHẨM <FaChevronDown className="text-secondary ms-2" />
                                        </div>
                                    </Dropdown>
                                </li>
                                <li className={activeItem === "tin-tuc" || activeItem === "bai-viet" ? "item-navbar active" : "item-navbar"}>
                                    <Link to={"/tin-tuc"}>TIN TỨC</Link>
                                </li>
                                <li className={activeItem === "lien-he" ? "item-navbar active" : "item-navbar"}>
                                    <Link to={"/lien-he"}>LIÊN HỆ</Link>
                                </li>
                                <li>
                                    <a href={"https://apps.apple.com/vn/app/hpay/id6479690061"} target="_blank" className="button-link">
                                        <MdOutlineFileDownload className="me-1 fs-5" />
                                        Tải app
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-9 d-block d-lg-none d-flex justify-content-end">
                            <button
                                style={{ border: "0", background: "transparent" }}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                            >
                                <img src={menu} alt="menu" />
                            </button>
                            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                                <div className="offcanvas-header">
                                    <Link to={"/"} style={{ width: "108px", height: "47px" }} data-bs-dismiss="offcanvas" aria-label="Close">
                                        <img src={logo} className="img-fluid" alt="" />
                                    </Link>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                                </div>
                                <div className="offcanvas-body">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <div className="py-3 ps-4">
                                                <Link to={"/"} className="d-block w-100" data-bs-dismiss="offcanvas" aria-label="Close">
                                                    TRANG CHỦ
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="py-3 ps-4">
                                                <Link to={"/ve-chung-toi"} className="d-block w-100" data-bs-dismiss="offcanvas" aria-label="Close">
                                                    VỀ HPAY
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo"
                                                >
                                                    SẢN PHẨM
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="ms-3 pb-1 border-bottom">
                                                        <Link
                                                            to={"/san-pham"}
                                                            className="d-block w-100"
                                                            data-bs-dismiss="offcanvas"
                                                            aria-label="Close"
                                                        >
                                                            CỔNG THANH TOÁN
                                                        </Link>
                                                    </div>
                                                    <div className="ms-3 pb-1 mt-3 border-bottom">
                                                        <Link
                                                            to={"/san-pham/vi-dien-tu"}
                                                            className="d-block w-100"
                                                            data-bs-dismiss="offcanvas"
                                                            aria-label="Close"
                                                        >
                                                            VÍ ĐIỆN TỬ
                                                        </Link>
                                                    </div>
                                                    <div className="ms-3 mt-3 ">
                                                        <Link
                                                            to={"/san-pham/thu-ho-chi-ho"}
                                                            className="d-block w-100"
                                                            data-bs-dismiss="offcanvas"
                                                            aria-label="Close"
                                                        >
                                                            CỔNG THU HỘ - CHI HỘ
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="py-3 ps-4">
                                                <Link to={"/tin-tuc"} className="d-block w-100" data-bs-dismiss="offcanvas" aria-label="Close">
                                                    TIN TỨC
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="py-3 ps-4">
                                                <Link to={"/lien-he"} className="d-block w-100" data-bs-dismiss="offcanvas" aria-label="Close">
                                                    LIÊN HỆ
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
